.Loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-spin-dot-item {
  background-color: #97D700!important;
}

.Contact {
  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    color: #603612;
  }
  &__description {
    font-weight: 300;
    color: #603612;
    text-align: center;
    padding-bottom: 20px;
  }

}
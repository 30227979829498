.Contacts {
  border-top: 0.5px solid #97D700;
  padding-top: 20px;
  > h1 {
    font-weight: 300;
    text-align: center;
    font-size: 40px;
    color: #603612;
    padding-bottom: 30px;
  }

  &__maps {
    display: flex;
    .map_text {
      margin-top: -17px;
      padding-left: 30px;
      .text {
        > p {
          font-weight: 300;
          > b {
            color: #603612;
            font-weight: 500;
          }

          > a {
            color: #603612;
          }

          > a:hover {
            border-bottom: 0.5px solid #603612;
          }
        }
      }
      .social {
        margin-top: 30px;
        padding-bottom: 20px;

        > h4 {
          color: #603612;
        }
      }
    }
  }

  &__icons_social {
    display: flex;
    > div {
      width: 60px;
      height: 60px;
      display: flex;
      margin-right: 20px;
      justify-content: center;
      align-items: center;
      background-color: rgba(96, 54, 18, 0.74);
      border-radius: 8px;
      > a {
        > svg {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  &__button {
    margin-top: 30px;
    width: 210px;
    height: 48px;
    background-color: rgba(96, 54, 18, 0.74)!important;
    color: #ffffff!important;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 300;
    border: none;
  }
}

@media (max-width: 1100px) {
  .Contacts {
    &__maps {
      flex-wrap: wrap;

      .map_text {
        padding-left: 0px;
        padding-top: 20px;
      }
    }
  }

  .ymaps-2-1-79-map-bg {
    width: 100%;
  }
}
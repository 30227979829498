*,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
}

.body {
  height: 40%;
  width: 100%;
  padding: 0;
  font-size: 3vmin;
}

.body {
  background: #000000;
  color: #fff;
  overflow: hidden;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slides {
  display: grid;
  > .slide {
    grid-area: 1 / -1;
    cursor: pointer;
  }

  > button {
    appearance: none;
    background: transparent;
    border: none;
    color: white;
    position: absolute;
    font-size: 5rem;
    width: 5rem;
    height: 5rem;
    top: 30%;
    transition: opacity 0.3s;
    opacity: 0.7;
    z-index: 5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
      left: -50%;
    }
    &:last-child {
      right: -50%;
    }
  }
}
.slideContent {
  width: 30vw;
  height: 40vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;

  display: grid;
  align-content: center;

  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(100% * var(--offset)))
  rotateY(calc(-45deg * var(--dir)));
}

.slideContentInner {
  transform-style: preserve-3d;
  transform: translateZ(2rem);
  transition: opacity 0.3s linear;
  text-shadow: 0 0.1rem 1rem #000;
  opacity: 0;
  text-align: left;
  .slideSubtitle,
  .slideTitle {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.2ch;
    text-transform: uppercase;
    margin: 0;
    color: #ffffff;
    padding-left: 15px;
    padding-bottom: 15px;
  }

  .slideSubtitle::before {
    content: "— ";
  }

  .slideDescription {
    margin: 0;
    font-size: 0.8rem;
    letter-spacing: 0.2ch;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 300;
    color: #ffffff;
  }
}

.slide[data-active] {
  z-index: 2;
  pointer-events: auto;

  .slideBackground {
    opacity: 0.2;
    transform: none;
  }

  .slideContentInner {
    opacity: 1;
  }

  .slideContent {
    --x: calc(var(--px) - 0.5);
    --y: calc(var(--py) - 0.5);
    opacity: 1;

    transform: perspective(1000px);

    &:hover {
      transition: none;
      transform: perspective(1000px) rotateY(calc(var(--x) * 45deg))
      rotateX(calc(var(--y) * -45deg));
    }
  }
}

@media (max-width: 900px) {
  .slideContentInner .slideSubtitle, .slideContentInner .slideTitle {
    font-size: 28px;
  }
}

@media (max-width: 900px) {
  .slideContentInner .slideSubtitle, .slideContentInner .slideTitle {
    font-size: 24px;
  }
}

@media (max-width: 760px) {
  .body {
    height: 300px;
  }

  .slideContent {
    width: 210px;
    height: 300px;
  }

  .slideContentInner .slideSubtitle, .slideContentInner .slideTitle {
    font-size: 20px;
  }
}

@media only screen and (max-device-width: 580px) {
  .slides > button:last-child{
    //right: -66% !important;
  }

  .slides > button:first-child {
    left: -140px !important;

  }
}

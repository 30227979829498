.About {
  margin-top: 60px;
  > h1 {
    font-weight: 300;
    text-align: center;
    font-size: 60px;
    color: #603612;
  }

  > p {
    width: 900px;
    margin: 0 auto;
    text-align: center;
    font-weight: 200;
    font-size: 18px;
    color: #603612;
    line-height: 1.4;
    > b {
      font-weight: 400;
    }
  }

  &__block_about {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    > div {
      margin-top: 15px;
      margin-right: 15px;
      display: flex;
      height: 170px;
      background-color: #B5E191;
      border-radius: 16px;
      .icons {
        padding: 30px 46px 30px 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #279439;
        border-radius: 16px;

        > svg {
          width: 90px;
          height: 90px;
          color: #603612;
        }
      }

      > p {
        height: 120px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        font-weight: 300;
        font-size: 18px;
        color: #1a6012;
      }
    }
  }

  &__border {
    padding-bottom: 70px;
    border-bottom: 0.5px dashed #97D700;
  }
}

@media (max-width: 990px) {
  .About {
    > p {
      width: 100%;
    }

    &__block_about {
      > div {
        .icons {
          padding: 20px 36px 10px 36px;

          > svg {
            width: 70px;
            height: 70px;
          }
        }
        > p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .About {
    > p {
      width: 100%;
    }

    &__block_about {
      grid-template-columns: 1fr;
      > div {
        .icons {
          padding: 30px 46px 30px 46px;

          > svg {
            width: 90px;
            height: 90px;
          }
        }
        > p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .About {
    > h1 {
      font-size: 40px;
    }

    > p {
      font-size: 18px;
    }

    &__block_about {
      grid-template-columns: 1fr;
      > div {
        .icons {
          padding: 30px 46px 30px 46px;

          > svg {
            width: 80px;
            height: 80px;
          }
        }
        > p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .About {
    > h1 {
      font-size: 40px;
    }

    > p {
      font-size: 18px;
    }

    &__block_about {
      grid-template-columns: 1fr;
      > div {
        margin-right: 0px;
        .icons {
          padding: 20px 36px 20px 36px;

          > svg {
            width: 80px;
            height: 80px;
          }
        }
        > p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .About {
    > h1 {
      font-size: 40px;
    }

    > p {
      font-size: 18px;
    }

    &__block_about {
      grid-template-columns: 1fr;
      > div {
        margin-right: 0px;
        .icons {
          padding: 20px 36px 20px 36px;

          > svg {
            width: 80px;
            height: 80px;
          }
        }
        > p {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .About {
    > h1 {
      font-size: 40px;
    }

    > p {
      font-size: 18px;
    }

    &__block_about {
      display: none;
      grid-template-columns: 1fr;
      > div {
        margin-right: 0px;
        .icons {
          display: none;

          > svg {
            width: 80px;
            height: 80px;
          }
        }
        > p {
          font-size: 16px;
        }
      }
    }
  }
}
.Header {
  background-color: #E6E6E5;
  padding-top: 5px;
  width: 100%;
  padding-bottom: 5px;
  position: fixed;
  top: 0;
  z-index: 1000;
  &__block_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__course {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #603612;
    padding-top: 26px;
    padding-left: 20px;
    > span {
      font-size: 12px;
      line-height: 20px;
      color: #999;
    }
  }

  &__menu {
    display: none;
    cursor: pointer;
  }

  &__logo {
    display: flex;
    > div {
      > img {
        width: 80px;
        height: 80px;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    > li {
      list-style: none;
      padding-right: 30px;
      > a {
        color: #603612;
        font-weight: 200;
        font-size: 16px;
      }

      > a:hover {
        border-bottom: 1px solid #603612;
      }
    }
  }
  &__contact {
    line-height: 1.2;
    > a {
      color: #603612;
      font-weight: 200;
      font-size: 18px;
    }

    > a:hover{
      border-bottom: 1px solid #603612;
    }

    .tel {
      float: right;
    }
    .tel:hover {
      border-bottom: 1px solid #603612;
    }
  }

  &__mobile {
    display: none;
  }

  &__menu_nav {
    padding-left: 20px;
    padding-top: 40px;
    > p {
      > a {
        color: #603612;
        font-size: 20px;
        font-weight: 300;
      }

      > a:hover {
        border-bottom: 0.5px solid #603612;
      }
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.ant-drawer-header {
  display: none!important;
}

.ant-drawer-body {
  padding: 0!important;
}
.drawer-class:focus {
  outline: none;
}

@media (max-width: 1130px) {
  .Header {
    &__list {
      display: none;
    }

    &__menu {
      display: block;
      cursor: pointer;
    }
    &__contact {
      display: none;
    }

    &__mobile {
      display: block;
      cursor: pointer;
      > span {
        > svg {
          font-size: 20px;
        }
      }
    }
  }

  .ant-dropdown-menu {
    background-color: #97D700!important;
    top: 10px;
    width: 170px;
    right: -20px;
  }
}

.ant-dropdown-menu-title-content > a {
  color: #ffffff!important;
  font-weight: 300;
  font-size: 15px;
}
body {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    background-color: #ffffff!important;
}

html {
    scroll-behavior: smooth;
}


a {
    text-decoration: none;
}

.link {
    cursor: pointer;
}
.container {
    width: 1170px;
    margin: 0 auto;
}

.paddingTopHeader {
    padding-top: 94px;
}

.ant-modal-title {
    color: #603612!important;
}

.btn {
    width: 100%;
    height: 40px;
    border: none;
    color: #ffffff!important;
    border-radius: 16px;
    background-color: #603612;
}

.ant-form-item-required {
    color: #603612!important;
}

.ant-form-item-required::before {
    content: '' !important;
}

@media (max-width: 1240px) {
    .container {
        width: 100%;
        margin: 0 auto;
        padding-right: 20px;
        padding-left: 20px;
    }
}



.Info {
  > div {
    > h1 {
      font-weight: 500;
      color: #603612;
      font-size: 28px;
      padding-top: 20px;
      padding-bottom: 10px;
      border-bottom: 0.5px solid #603612;
    }
    > div {
      > img {
        width: 550px;
        height: 350px;
        border-radius: 8px;
      }
    }
  }

  &__section {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  &__fons {
    background-position: left 50% top 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin-top: unset;
    margin-bottom: unset;
    > div {
      background-color: rgba(0, 0, 0, 0.45);
      padding-top: 190px;
      padding-bottom: 190px;

      > h1 {
        text-align: center;
        color: #ffffff;
        font-weight: 300;
        margin-top: -20px;
        font-size: 35px;
      }
    }
  }
  &__description {
    font-weight: 200!important;
    font-size: 16px;
    color: #6a6c67;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.container2 {
  padding-right: 20px;
  padding-left: 20px;
}

@media (max-width: 620px) {
  .Info {
    > div {
      > h1 {
        font-weight: 500;
        color: #279439;
        font-size: 28px;
        padding-top: 20px;
      }
      > div {
        > img {
          width: 100%;
          border-radius: 8px;
        }
      }

      > p {
        width: 80%;
        font-weight: 300;
      }
    }
  }

  .container2 {
    padding-left: 0;
    padding-right: 0;
  }
}
.KofeFried {
  > h1 {
    font-weight: 500;
    color: #915113;
    font-size: 28px;
    padding-top: 20px;
    margin-bottom: -10px;
    padding-bottom: 15px;
    border-bottom: 0.5px solid #915113;
  }

  > p {
    padding-top: 10px;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: -10px;
    color: #555751;
    line-height: 1.3;
  }
}